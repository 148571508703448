<template>
  <div>
        <Purchase-add-new
      :is-add-new-purchase-sidebar-active.sync="isAddNewPurchaseSidebarActive"
      @clicked="one"
    />

    <b-modal
      id="modal-xl"
      ref="my-modal"
      no-close-on-backdrop
      ok-title="Close"
      scrollable
      centered
      button-size="sm"
      size="xl"
      title="Membership Contract Review"
    >
     <embed :src="iframe_src" width="100%" height="600px" type="application/pdf">
      <template #modal-footer="{ ok , closeModal  }">

      <b-button size="sm" variant="primary" @click="print()" v-if="selected.is_pdf == 'N'">
        Print
      </b-button>
      <b-button size="sm" variant="success" @click="send()" v-if="selected.epath != ''">
        Send
      </b-button>
      <!-- Button with custom close trigger value -->
      <b-button size="sm" variant="outline-secondary" @click="close()">
        Close
      </b-button>
    </template>
      </b-modal>

    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-form-group>
          <v-select
            v-model="perPage"
            label="Number of rows"
            :options="pageOptions"
            :clearable="false"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchString"
            class="d-inline-block mr-1"
            placeholder="Search..."
          />
          <b-button
            variant="primary"
            @click="add_membership()"
          >
            <span class="text-nowrap">Add Membership</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      striped
      class="mb-0"
      @row-selected="onRowSelected"
    >
    <template #cell(status_number)="data">
      <b-badge :variant="status[1][data.value]">
        {{ status[0][data.value] }}
      </b-badge>
    </template>
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :to="{ name: 'apps-Member-view', params: { id: data.item.id } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Details</span>
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'apps-Member-edit', params: { id: data.item.id } }">
            <feather-icon icon="Edit1Icon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(button-content)="data">
        <!--<feather-icon
          icon="EditIcon"
          size="16"
          class="align-middle text-body mr-1"
          @click="edit(data)"
        />-->
          <feather-icon
            v-if="data.item.epath != null"
            icon="FileTextIcon"
            size="16"
            class="align-middle text-body"
            @click="open_file(data.item.epath)"
          />
          <feather-icon
            v-if="data.item.overdue > 0"
            icon="CreditCardIcon"
            size="16"
            class="align-middle text-body ml-1"
            @click="pay(data.item.member_plan_id)"
          />
      </template>

    </b-table>
    <b-row>
      <b-col class="mt-1">
        Total : {{ totalRows }} , Display Row {{ startRow }} to {{ endRow }}
      </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          align="right"
          size="md"
          class="mt-1"
          @change="handleChangePage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import PurchaseAddNew from './SidebarNewPurchase.vue'
import vSelect from 'vue-select'
import Nl2br from 'vue-nl2br'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import {
  BTable, BAvatar, BCardBody, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BInputGroupPrepend, BModal, VBModal
} from 'bootstrap-vue'
import router from '@/router'
export default {
  components: {
    PurchaseAddNew,
    router,
    BTable,
    Nl2br,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BPagination,
    BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      iframe_src : '//cloudfit.pro/invoice.php',
      genderOptions: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
      ],
      planOptions: [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ],
      statusOptions: [
        { label: 'Active', value: 'active' },
        { label: 'Disabled', value: 'disabled' },
      ],
      centerOptions: [],
      transaction_id:null,
      transaction:null,
      isAddNewPurchaseSidebarActive: false,
      searchString: null,
      perPage: 10,
      startRow: 1,
      endRow: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      fields: [{ key: 'code', label: 'Code' },  { key: 'start', label: 'Start' },  { key: 'end', label: 'Expire' }, { key: 'amount', label: 'Amount' },{ key: 'transaction_code', label: 'transaction code' }, { key: 'overdue', label: 'OVERDUE' }, { key: 'status_number', label: 'STATUS' },{key:'button-content',label:'ACTION'}],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'ACTIVE', 2: 'DISABLED', 3: 'FREEZE', 4: 'TERMINATE', 5: 'PENDING',
      },
      {
        1: 'light-success', 2: 'danger', 3: 'info', 4: 'primary', 5: 'light-info',
      }],
      selectMode: 'single',
      selected: [],
    }
  },
  watch: {
    currentPage: {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    searchString: {
      handler(newValue, oldValue) {
        // console.log("search")
        this.handleSearch()
      },
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue < 9) {
          this.perPage = 10
        }
        // console.log("search")
        this.handleSearch()
      },
    },
  },
  created() {
    this.get_data()
    this.get_center_data()
  },
  methods: {
    add_membership() {
      this.$router.push('/apps/member/'+ router.currentRoute.params.id+'/membership/add/')
    },
    one() {
      this.isAddNewMemberSidebarActive = false
      this.get_data()
    },
    get_data() {
       this.$http.get(process.env.VUE_APP_API_BASE+`/member/${router.currentRoute.params.id}/memberships/?perpage=${this.perPage}&page=${this.currentPage}&search=${this.searchString}`)
           .then(res => {
          this.items = res.data.memberships
          this.totalRows = res.data.total
          this.startRow = res.data.startRow
          this.endRow = res.data.endRow
          //  console.log( res.data.members.length)
        })
    },
    get_center_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/centers/')
        .then(res => {
          this.centerOptions = res.data.centers
        })
    },
    handleChangePage() {

    //  this.get_data()
    },
    onRowSelected(items) {
      this.selected = items[0]
      console.log(items)

        this.$router.push(`/apps/cloudfit/membership/${items[0].id}/`)


    },
    close() {
      this.iframe_src = '//www.cloudfit.pro/invoice.php'
      this.$refs['my-modal'].hide()
    },
    print() {
      this.iframe_src = '//www.cloudfit.pro/invoice.php?print=y&code='+this.transaction_id
    },
    send() {
      this.$http.post(process.env.VUE_APP_API_BASE+'/membership/'+this.selected.id+'/send/',{})
        .then(res => {
          this.$refs['my-modal'].hide()
        })
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    handleSearch() {
      // let _print = `The user searched for: ${searching}\n`
      // this.$refs.pre.appendChild(document.createTextNode(_print))
    // this.searchString = searching
    // this.currentx = 1
    // this.get_data()
      this.searchTimeOut()
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        // your code
        this.currentPage = 1
        this.get_data()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
