<template>
  <b-card>
    <!-- Alert: No item found -->
    <b-tabs pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Account') }}</span>
        </template>
        <tab-member-information
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <!-- <b-tab v-if="client_module.module.includes('membership')">
        <template #title>
          <feather-icon
            icon="KeyIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Membership') }}</span>
        </template>
        <tab-member-membership
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab> -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="ShoppingCartIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Purchase') }}</span>
        </template>
        <tab-member-purchase-record
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <!-- <b-tab  v-if="client_module.module.includes('class')">
        <template #title>
          <feather-icon
            icon="PackageIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Class') }}</span>
        </template>
        <tab-member-class
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab> -->
      <b-tab  v-if="client_module.module.includes('package')">
        <template #title>
          <feather-icon
            icon="ActivityIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Package') }}</span>
        </template>
        <tab-member-package
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab  v-if="client_module.module.includes('entry')">
        <template #title>
          <feather-icon
            icon="LogInIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Entry') }}</span>
        </template>
        <tab-member-entry
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab  v-if="client_module.module.includes('credit_card')">
        <template #title>
          <feather-icon
            icon="CreditCardIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Credit Card') }}</span>
        </template>
        <tab-member-credit-card
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BAlert, BLink, BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BTab, BTabs,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import TabMemberInformation from './TabMemberInformation.vue'
import TabMemberMembership from './TabMemberMembership.vue'
import TabMemberPurchaseRecord from './TabMemberPurchaseRecord.vue'
import TabMemberClass from './TabMemberClass.vue'
import TabMemberPackage from './TabMemberPackage.vue'
import TabMemberEntry from './TabMemberEntry.vue'
import TabMemberCreditCard from './TabMemberCreditCard.vue'
export default {
  components: {
    TabMemberInformation,
    TabMemberEntry,
    TabMemberCreditCard,
    TabMemberPurchaseRecord,
    TabMemberPackage,
    TabMemberMembership,
    TabMemberClass,
    ToastificationContent,
    vSelect,
    flatPickr,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
  },
  data() {
    return {
      iframe_src: '',
      paymentMethods:[],
      centerOptions: [],
      client_module:JSON.parse(localStorage.getItem('userData')),
      userData: {
        display_name: null,
        exsiting_username: null,
      },
      statusOptions: [
        { label: 'Active', value: 'active' },
        { label: 'Disabled', value: 'disabled' },
      ],
      genderOptions: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
      ],

    }
  },
  created() {
    this.get_member_data()
    this.get_center_data()
  },
  methods: {
    save_member() {
      this.$http.post(process.env.VUE_APP_API_BASE+`/member/${this.userData.member_id}/`, this.userData)
        .then(res => {
          if (res.data.result === true) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
          }
        })
    },
    get_center_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/centers/')
        .then(res => {
          this.centerOptions = res.data.centers
        })
    },

    get_member_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/member/${router.currentRoute.params.id}/`)
        .then(res => {
          if (process.env.VUE_APP_VERSION != res.data.version) {
            this.$router.go(0);
          }
          this.userData = res.data
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
