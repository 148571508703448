<template>
<div>
  <Snapshot
    :is-snap-shot-sidebar-active.sync="isSnapShotSidebarActive"
    :user-data="userData"
    user-type="MEMBER"
    @clicked="one"
  />
    <!-- Header: Personal Info -->
    <b-modal
      id="modal-xl"
      ref="my-modal"
      no-close-on-backdrop
      ok-title="Close"
      scrollable
      centered
      button-size="sm"
      size="lg"
      title="Snapshot"
    >
     <embed src="//www.cloudfit.pro/snapshot.php" width="100%" height="600px">
      <template #modal-footer="{ ok , closeModal  }">

      <!-- Button with custom close trigger value -->
      <b-button size="sm" variant="outline-secondary" @click="close()">
        Close
      </b-button>
    </template>
      </b-modal>


    <b-row>
        <b-col md="8" lg="9">
          <div class="d-flex">
              <feather-icon icon="UserIcon" size="19"/>
              <h4 class="mb-0 ml-50">
                  {{ $t('Personal Information') }}
              </h4>
          </div>
            <!-- Form: Personal Info Form -->
            <b-form class="mt-1">
                <b-row>

                    <!-- Field: Mobile -->
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('Member Code')" label-for="member_code">
                            <b-form-input id="member_code" v-model="userData.member_code"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group label="Chinese Name" label-for="zh_name">
                            <b-form-input id="zh_name" v-model="userData.zh_name"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group label="English Name" label-for="en_name">
                            <b-form-input id="en_name" v-model="userData.en_name"/>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="6" lg="4" v-if="$can('read', 'staff')">
                        <b-form-group label="Date Of Birth" label-for="birth-date">
                            <flat-pickr v-model="userData.birth" class="form-control" :config="{ dateFormat: 'Y-m-d'}" placeholder="YYYY-MM-DD"/>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('E-Mail')" label-for="email">
                            <b-form-input id="email" v-model="userData.email"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('Mobile')" label-for="mobile">
                            <b-form-input id="mobile" v-model="userData.mobile"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group label="Address" label-for="address">
                            <b-form-input id="address" v-model="userData.address"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4" v-if="$can('read', 'staff')">
                        <b-form-group label="Identity Number" label-for="identity_number">
                            <b-form-input id="identity_number" v-model="userData.identity_card"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group label="Gender" label-for="gender">
                            <v-select v-model="userData.gender" :options="genderOptions" :clearable="false" input-id="gender"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('Created at')" label-for="created_at">
                            <b-form-input id="created_at" v-model="userData.created_at" disabled/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4" v-if="$can('read', 'staff')">
                        <b-form-group :label="$t('Personal Trainer')" label-for="Personal Trainer">
                            <v-select v-model="userData.user_id" :reduce="ans => ans.value" :options="staffOptions" :clearable="true" multiple input-id="user_id"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('Status')" label-for="status">
                            <v-select v-model="userData.status" :reduce="ans => ans.value" :options="statusOptions" :clearable="false" input-id="status"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="8">
                        <b-form-group :label="$t('Remarks')" label-for="remarks">
                            <b-form-textarea id="remarks" v-model="userData.remarks"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('Token')" label-for="created_at">
                            <b-form-input id="created_at" v-model="userData.token" disabled/>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mt-2 mb-2">
                    <b-col>
                        <b-button
                            variant="primary"
                            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                            @click="save_member"
                            v-if="$can('read', 'staff')">
                            Save Changes
                        </b-button>
                        <b-button variant="outline-secondary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" :block="$store.getters['app/currentBreakPoint'] === 'xs'" :to="{ name: 'member-list' }">
                            Cancel
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-col>
        <b-col  md="4" lg="3">

          <img
            style="width:100%;border-radius:10px;"
            ref="previewEl"
            @error="userData.avatar = ''"
            :src="userData.avatar"
            size="100%"
            @click="update_to_device()"
            rounded
          /><br>
          <b-button
            class="mt-1"
            block
            variant="primary"
            @click="snapshot2()"
          >
          <feather-icon icon="CameraIcon" />
           <span> {{ $t('Start Camera') }} </span>
          </b-button>
          <b-button
            class="mt-1"
            block
            variant="outline-primary"
            @click="update_card()"
          >
          <feather-icon icon="RssIcon" />
          <span  v-if="userData.card_id"> {{ $t('Update Card') }} </span>
          <span  v-if="!userData.card_id"> {{ $t('Register Card') }} </span>
          </b-button>

          <b-button
            class="mt-1"
            block
            size="sm"
            variant="outline-success"
            @click="update_to_device()"
          >
          <feather-icon icon="RefreshCwIcon" />
           <span> {{ $t('Update Access Control') }} </span>
          </b-button>

    <!--      <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->
        <!--  <v-easy-camera
            v-model="picture"
            ref="cam"
            mustApprove
            :startOnMounted="startOnMounted"
            :autoDetectMobile="startOnMounted"
          />
          <b-button
            variant="primary"
            @click="capture()"
            v-if="capturing===true"
          >
          Capture
        </b-button>-->
          <hr>
        <h4 class="mb-2"> <feather-icon icon="DollarSignIcon" size="19" /> {{ $t('Deposit') }}</h4>
            <b-list-group>
                <b-list-group-item v-for="deposit in userData.deposit" :key="index" class="d-flex justify-content-between align-items-center">
                    <span><b>{{deposit.code}}</b><br><span style="font-size:13px;color:#aaa;">{{deposit.created_at.substring(0,10)}}</span></span>
                    <b-badge variant="primary" pill class="badge-round">
                        {{deposit.amount}}
                    </b-badge>
                    <b-badge variant="danger" pill class="badge-round" @click="void_deposit(deposit.transaction_id)">
                        <feather-icon icon="Trash2Icon" size="19"/>
                    </b-badge>
                </b-list-group-item>

            </b-list-group>
        </b-col>
    </b-row>
</div>
</template>



<script>
import {
   BFormTextarea, BCardText, BAlert, BLink, BCard,BMedia, BAvatar, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BTab, BTabs,BListGroup, BListGroupItem,BBadge
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Snapshot from './SidebarSnapshot.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import EasyCamera from 'easy-vue-camera';


export default {
  components: {
    ToastificationContent,
     BFormTextarea,
 'v-easy-camera': EasyCamera,
     BBadge,
     BCard,
     Snapshot,
     BCardText,
    vSelect,
    flatPickr,
    BAvatar,
    BListGroup, BListGroupItem,
    BForm,
    BFormGroup,
    BMedia,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
  },
  data() {
    return {
      picture: null,
      capturing : false,
      startOnMounted: false,
      centerOptions: [],
      isSnapShotSidebarActive:false,
      staffOptions: [],
      userData: {
        display_name: null,
        exsiting_username: null,
      },
      statusOptions: [
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Disable', value: 'DISABLED' },
        { label: 'Deleted', value: 'DELETED' },
      ],
      genderOptions: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
      ],

    }
  },
  created() {
    this.get_member_data()
    this.get_center_data()
    this.get_staff_list()
  },
  methods: {
    one() {
      this.isSnapShotSidebarActive = false
      this.get_member_data()
    },
    update_card() {
         localStorage.setItem('reader_action', 'update_member_card')
         localStorage.setItem('member_id', this.userData.member_id)
         this.$swal({
          title: 'Please Scan NFC Card',
          icon: 'info',
          allowOutsideClick: false,
          confirmButtonText: this.$t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            localStorage.setItem('reader_action', 'normal')
            localStorage.removeItem('member_id')
          }
        })
    },
    go() {
        window.location = 'https://www.cloudfit.pro/snapshot.php?token='+this.userData.token
    },
    snapshot2() {
      this.isSnapShotSidebarActive = true
    },
    start_cam() {
      this.capturing = true
      this.$refs.cam.start()
      this.$refs.cam.start()
    },
    capture() {
      this.$refs.cam.snap()
    },
    old_capture() {
      this.$refs['my-modal'].show()
    },
    void_deposit(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, void deposit!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.$http.post(process.env.VUE_APP_API_BASE+`/transaction/`+id+`/delete/`, {})
          .then(res => {
              this.get_member_data()
          })

      })
    },
    get_staff_list() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/staffs/select/')
        .then(res => {
          this.staffOptions = res.data
        })
    },
    update_to_device() {
      this.$http.post(`https://www.cloudfit.pro/access_control/api/v1/upload/${router.currentRoute.params.id}`)
        .then(res => {
          if (res.data.result === true) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
          }
        })
    },
    save_member() {
      this.$http.post(process.env.VUE_APP_API_BASE+`/member/${this.userData.member_id}/`, this.userData)
        .then(res => {
          if (res.data.result === true) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
          }
        })
    },
    snapshot() {
      this.$refs['my-modal'].show()
    },
    get_center_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/centers/')
        .then(res => {
          this.centerOptions = res.data.centers
        })
    },
    get_member_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/member/${router.currentRoute.params.id}/`)
        .then(res => {
          this.userData = res.data
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
