<template>
  <b-sidebar
    id="add-new-package-sidebar"
    :visible="isAddNewPurchaseSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="$emit('clicked',false);$refs.simpleRules.reset();packageData=blankPackageData"
    @change="(val) => $emit('one')"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Purchase
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <b-modal
      id="modal-xl"
      ref="my-modal"
      ok-only
      no-close-on-backdrop
      ok-title="Close"
      scrollable

      size="xl"
      title="Invoice Review"
    >
     <embed :src="iframe_src" width="100%" height="700px" type="application/pdf">

      </b-modal>
      <!--
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-xl
        variant="outline-primary"
      >
        Scrolling Content inside Modal
      </b-button>-->
      <!-- BODY -->
      <validation-observer
        ref="simpleRules"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
        <label class="section-label mb-1">Package Details</label>
        <validation-provider
            #default="validationContext"
            name="Package Name"
            rules="required"
          >
            <b-form-group
              label="Package"
              label-for="package"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="packageData.package"
                :clearable="false"
                input-id="package"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


        <validation-provider
              #default="validationContext"
              name="Expire Date"
              rules="required"
            >

              <b-form-group
                label="Expire Date"
                label-for="expire-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  :value="default_expiry_date"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d'}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
            #default="validationContext"
            name="Price"
          >
            <b-form-group
              label="Price"
              label-for="Price"
            >
              <b-form-input
                id="price"
                v-model="selected_package_price"
                readonly
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="quantity"
          >
            <b-form-group
              label="Quantity"
              label-for="Quantity"
            >
              <b-form-input
                id="quantity"
                v-model="selected_package_quantity"
                :readonly="lock_quantity"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        <hr class="my-2">
        <label class="section-label mb-1">Payment Details</label>


        <validation-provider
            #default="validationContext"
            name="Payment Method"
            rules="required"
          >
            <b-form-group
              label="Payment Method"
              label-for="payment-method"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="packageData.payment_method"
                :options="paymentMethodOptions"
                :clearable="false"
                input-id="paymentMethod"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
           <validation-provider
            #default="validationContext"
            rules="required|between:1,100000"
            name="Payment Amount"
          >
            <b-form-group
              label="Payment Amount"
              label-for="payment_amount"
            >
              <b-form-input
                id="payment_amount"
                v-model="packageData.payment_amount"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="submit_now()"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mb-4"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BModal, VBModal
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import countries from '@/@fake-db/data/other/countries'

export default {
  components: {
    ToastificationContent,
    flatPickr,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BModal, VBModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  model: {
    prop: 'isAddNewPurchaseSidebarActive',
    event: 'update:is-add-new-Purchase-sidebar-active',
  },
  props: {
    isAddNewPurchaseSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    default_expiry_date() {
      try {
      var myCurrentDate=new Date();
      var myFutureDate=new Date(myCurrentDate);
      var expiry_figure = Number(this.packageData.package.expiry_figure)
      switch(this.packageData.package.expiry_unit) {
        case "DAY":
          myFutureDate.setDate(myFutureDate.getDate()+ expiry_figure)
          break;
        case "MONTH":
          myFutureDate.setMonth(myFutureDate.getMonth()+ expiry_figure)
          break;
        case "YEAR":
          myFutureDate.setYear(myFutureDate.getFullYear()+ expiry_figure)
          break;
      }
      } catch {
        var myCurrentDate=new Date();
        var myFutureDate=new Date(myCurrentDate);
      }
      return myFutureDate.getFullYear() + "-" +  (myFutureDate.getMonth()+1)  + "-" + myFutureDate.getDate()
    },
    selected_package_price() {
      try {
      return this.packageData.package.price
      } catch {
        return 0
      }
    },
    selected_package_quantity() {
      try {
      switch (Number(this.packageData.package.lock_quantity)) {
        case 0:
          this.lock_quantity = false
          break;
        case 1:
          this.lock_quantity = true
          break;
      }
       return this.packageData.package.quantity
      } catch {
      return 0
      }

    }
  },
  data() {
    return {
      iframe_src : '//www.cloudfit.pro/invoice.php',
      required,
      paymentMethodOptions: [
        { label: 'Cash', value: 'cash' },
        { label: 'Visa', value: 'visa' },
      ],
      lock_quantity : true,
      alphaNum,
      email,
      countries,
      blankPackageData: {
        expire_at: '',
      },
      packageData:{
        center_id:0,
        package:{
          price:0.00,
          quantity:0,
          payment_method:'',
          expiry_figure:'1',
          expiry_unit:'DAY'
        }
      }
    }
  },
  created() {
window.onafterprint = function() {
                    alert("123")
                  }
  },
  methods: {
    resetForm() {

    },
    async print () {
      // Pass the element id here
      await this.$htmlToPaper('printMe');
    },
    submit_now() {
      this.packageData.center_id = this.$store.getters['center/currentCenterId']
      this.packageData.expire_at = this.default_expiry_date
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$http.post(process.env.VUE_APP_API_BASE+'/member/'+this.userData.member_id+'/package/add/', this.packageData)
            .then(res => {
              if (res.data.result === true) {
                this.$emit('clicked', false)
                this.packageData = this.blankPackageData
                this.$refs.simpleRules.reset()
                this.iframe_src = '//www.cloudfit.pro/invoice.php?print=n&code='+res.data.transaction_id
                this.$refs['my-modal'].show()

              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })
        }
      })
    },
  },
  setup(props, { emit }) {
    const blankPackageData = {
      expire_at:'',
      quantity:'',
      package: {
        expiry_figure:'1',
        expiry_unit:'DAY'
      }
    }

    const packageData = ref(JSON.parse(JSON.stringify(blankPackageData)))
    const resetuserData = () => {
      packageData.value = JSON.parse(JSON.stringify(blankPackageData))
    }

    const onSubmit = () => {

    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetuserData)

    return {
      packageData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
